import { useState, useEffect, createContext } from "react";
import {
  PRODUCTION_API_URL,
  ENGLISH_LABELS_API_URL,
  SPANISH_LABELS_API_URL,
} from "../constants/general";
import { getCurrentLanguageFromURL } from "../Helpers/general";
import TheApiFailurePage from "../components/FailedMessagePages/TheApiFailurePage";
import axios from "axios";

const JobPostingAPIDataContext = createContext({
  allJobPosts: [],
  allTopics: [],
  allLocationsWithCountries: [],
  currentPageLanguage: "eng",
});

export const JobPostingAPIDataContextProvider = (props) => {
  // Current page language
  const currentPageLanguage = getCurrentLanguageFromURL();

  // all job posts state
  const [allJobPosts, setAllJobPosts] = useState([]);

  // all topics state
  const [allTopics, setAllTopics] = useState([]);

  // all location and countries state
  const [allLocationsWithCountries, setAllLocationsWithCountries] = useState(
    []
  );

  // all labels as per the current language
  const [allLabels, setAllLabels] = useState([]);

  //
  const [ApiError, setApiError] = useState(false);

  const getAllData = async () => {
    try {
      //
      const { data } = await axios.get(PRODUCTION_API_URL);

      //
      setAllJobPosts(data.all_job_posts);
      //
      setAllTopics(data.all_topics);
      //
      setAllLocationsWithCountries(data.all_job_location_with_country);
    } catch (error) {
      //
      setApiError(true);
    }
  };

  const getAllLabelsAsPerCurrentLanguage = async (currentPageLanguage) => {
    try {
      //
      let labelApiURL = "";

      //
      if (currentPageLanguage === "eng") {
        //
        labelApiURL += ENGLISH_LABELS_API_URL;
      } else {
        //
        labelApiURL += SPANISH_LABELS_API_URL;
      }

      const { data } = await axios.get(labelApiURL);

      //
      setAllLabels(data);
    } catch (error) {
      //
      setApiError(true);
    }
  };

  // GET the all data and set in the corresponding state.
  useEffect(() => {
    getAllData();
  }, []);

  // GET the labels as per the current language and set in the state.
  useEffect(() => {
    getAllLabelsAsPerCurrentLanguage(currentPageLanguage);
  }, [currentPageLanguage]);

  if (
    allJobPosts.length !== 0 &&
    allTopics.length !== 0 &&
    allLocationsWithCountries !== 0 &&
    allLabels.length !== 0
  ) {
    return (
      <JobPostingAPIDataContext.Provider
        value={{
          allJobPosts: allJobPosts,
          allTopics: allTopics,
          allLocationsWithCountries: allLocationsWithCountries,
          allLabels: allLabels,
          currentPageLanguage: currentPageLanguage,
        }}
      >
        {props.children}
      </JobPostingAPIDataContext.Provider>
    );
  } else {
    if (ApiError) {
      return <TheApiFailurePage />;
    } else {
      return <div className="loader-circleborder"></div>;
    }
  }
};

export default JobPostingAPIDataContext;
