import { useContext } from "react";
import JobPostingFilterOperationContext from "../context/JobPostingFilterOperationContext";

const ApplyFiltersButton = () => {
  //
  const { allLabels, applyTheFilters } = useContext(
    JobPostingFilterOperationContext
  );

  return (
    <div className="module_options_filter-button">
      <button
        className="btn button--filter"
        onClick={applyTheFilters}
        tabIndex="0"
      >
        {allLabels.apply_filters_label}
      </button>
    </div>
  );
};

export default ApplyFiltersButton;
