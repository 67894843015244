import { useContext } from "react";
import { Link } from "react-router-dom";
import { htmlDecode } from "../Helpers/general";
import JobPostingFilterOperationContext from "../context/JobPostingFilterOperationContext";

const JobResults = () => {
  //
  const {
    allLabels,
    currentActivePageNumber,
    jobPostsAsPerTheCondition,
    currentURLQueryString,
    currentPageLanguage,
    updateTheFilterData,
  } = useContext(JobPostingFilterOperationContext);

  // console.log(jobPostsAsPerTheCondition);

  //
  let isNeedTheLoadMoreButton;

  //
  if (currentActivePageNumber < jobPostsAsPerTheCondition.totalNumberOfPages) {
    isNeedTheLoadMoreButton = true;
  } else {
    isNeedTheLoadMoreButton = false;
  }

  const newTitle = allLabels.site_title;

  if (document.title !== newTitle) {
    document.title = newTitle + " - " + allLabels.site_tagline;
  }

  //
  const loadMoreClickActionHandler = (currentActivePageNumber) => {
    //
    updateTheFilterData("nextPage", currentActivePageNumber + 1);
  };

  //
  const loadMoreEnterActionHandler = (event, currentActivePageNumber) => {
    if (event.key === "Enter") {
      //
      updateTheFilterData("nextPage", currentActivePageNumber + 1);
    }
  };

  return (
    <>
      <div className="module_items-container">
        {jobPostsAsPerTheCondition.jobPosts.length !== 0 ? (
          jobPostsAsPerTheCondition.jobPosts.map((item) => (
            <div className="module_item" tabIndex="0">
              <div className="module-jobs_headline">
                <h4>
                  <Link to={item.slug + currentURLQueryString}>
                    {item.name ? htmlDecode(item.name) : ""}
                  </Link>
                </h4>
                <div className="module-jobs_id">
                  {allLabels.requisition_no_label}:{" "}
                  {item.requisition_no ? item.requisition_no : ""}
                </div>
                <div className="module_item-bottom">
                  {item.requisition_no !== "JR106" ? (
                    <div className="module-jobs_location">
                      <span className="module-jobs_label">
                        {allLabels.location_label}:{" "}
                      </span>
                      <span className="module-jobs_value">
                        {item.job_posting_location
                          ? item.job_posting_location
                          : ""}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="module-jobs_contract-type">
                    <span className="module-jobs_label">
                      {allLabels.contract_type_label}:{" "}
                    </span>
                    <span className="module-jobs_value">
                      {item.contract_type ? item.contract_type : ""}
                    </span>
                  </div>
                  <div className="module-jobs_closing-date">
                    <span className="module-jobs_label">
                      {allLabels.starting_date_label}:{" "}
                    </span>
                    <span className="module-jobs_value">
                      {currentPageLanguage === "eng"
                        ? item.starting_date
                          ? item.starting_date
                          : ""
                        : item.starting_date_in_spanish
                        ? item.starting_date_in_spanish
                        : ""}
                    </span>
                  </div>
                  <div className="module-jobs_closing-date">
                    <span className="module-jobs_label">
                      {allLabels.closing_date_label}:{" "}
                    </span>
                    <span className="module-jobs_value">
                      {currentPageLanguage === "eng"
                        ? item.closing_date
                          ? item.closing_date
                          : ""
                        : item.closing_date_in_spanish
                        ? item.closing_date_in_spanish
                        : ""}
                    </span>
                  </div>
                  <div className="module-item-icon">
                    <Link to={item.slug + currentURLQueryString}>
                      <span></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="no-job-posts">
            <p>{allLabels.no_job_post_message}</p>
          </div>
        )}
      </div>

      <div className="module_pager module_pager--load">
        <li className="pager-prev pager-disabled"></li>
        {isNeedTheLoadMoreButton ? (
          <li
            className="pager-next btn"
            onClick={() => {
              loadMoreClickActionHandler(currentActivePageNumber);
            }}
            onKeyDown={(event) => {
              loadMoreEnterActionHandler(event, currentActivePageNumber);
            }}
            tabIndex="0"
          >
            {allLabels.load_more_btn_label}
          </li>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default JobResults;
