//
export const updateTheSearchKeywordInTheFilterData = (
  filterData,
  currentSearchKeyWord
) => {
  //
  return { ...filterData, searchKeyword: currentSearchKeyWord };
};

//
export const updateTheSelectedTopicInTheFilterData = (
  filterData,
  currentActiveTopics
) => {
  //
  return { ...filterData, activeTopicFilter: currentActiveTopics };
};

//
export const updateTheSelectedLocationInTheFilterData = (
  filterData,
  currentActiveLocation
) => {
  //
  return { ...filterData, activeLocationFilter: currentActiveLocation };
};

//
export const updateTheCurrentPageInTheFilterData = (filterData, pageNumber) => {
  //
  return { ...filterData, currentActivePageNumber: pageNumber };
};
