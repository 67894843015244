import { useEffect, useContext } from "react";
import SearchBar from "../SearchBar";
import JobResults from "../JobResults";
import LocationsFilter from "../Filters/LocationsFilter";
import CategoriesFilter from "../Filters/CategoriesFilter";
import ApplyFiltersButton from "../ApplyFiltersButton";
import { JobPostingFilterOperationContextProvider } from "../../context/JobPostingFilterOperationContext";
import JobPostingAPIDataContext from "../../context/JobPostingAPIDataContext";

const JobPostingPage = () => {
  // Scroll to the top of the page after render.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { allLabels } = useContext(JobPostingAPIDataContext);

  return (
    <div className="module-jobs_item--content">
      <JobPostingFilterOperationContextProvider>
        <div className="module-jobs_item">
          <SearchBar />
          <JobResults />
        </div>
        <div className="module-jobs_item-inner">
          <h4>{allLabels.filter_job_results_label}</h4>
          <div className="module_options">
            <LocationsFilter />
            <CategoriesFilter />
            <ApplyFiltersButton />
          </div>
        </div>
      </JobPostingFilterOperationContextProvider>
    </div>
  );
};

export default JobPostingPage;
