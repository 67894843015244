import {
  JOB_POSTS_UPDATING_OPERATIONS,
  JOB_POSTS_PER_PAGE,
} from "../constants/general";
import {
  initialUpdateOfTheJobPosts,
  updateTheJobPostsAsPerAppliedFilters,
} from "../Helpers/jobPostsUpdateHelper";

const jobPostsAsPerTheConditionReducer = (
  jobPostsAsPerTheCondition,
  action
) => {
  switch (action.type) {
    case JOB_POSTS_UPDATING_OPERATIONS.initialUpdateOfTheJobPosts:
      return initialUpdateOfTheJobPosts(
        { ...jobPostsAsPerTheCondition },
        action.data,
        JOB_POSTS_PER_PAGE
      );

    case JOB_POSTS_UPDATING_OPERATIONS.updateTheJobPostsAsperOnlyThePageNo:
    case JOB_POSTS_UPDATING_OPERATIONS.updateTheJobPostsAsPerAppliedFilters:
      return updateTheJobPostsAsPerAppliedFilters(
        { ...jobPostsAsPerTheCondition },
        action.data,
        JOB_POSTS_PER_PAGE
      );

    default:
      return { ...jobPostsAsPerTheCondition };
  }
};

export default jobPostsAsPerTheConditionReducer;
