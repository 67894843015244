import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { JobPostingAPIDataContextProvider } from "./context/JobPostingAPIDataContext";
import JobPostingPage from "./components/Pages/JobPostingPage";
import JobDetailsPage from "./components/Pages/JobDetailsPage";

const App = () => {
  return (
    <JobPostingAPIDataContextProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<JobPostingPage />} />
          <Route exact path="/es" element={<JobPostingPage />} />
          <Route path="/:slug" element={<JobDetailsPage />} />
          <Route path="/es/:slug" element={<JobDetailsPage />} />
        </Routes>
      </Router>
    </JobPostingAPIDataContextProvider>
  );
};

export default App;
