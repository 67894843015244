import { useState, useEffect, useContext } from "react";
import { listOutTheLocationsAsPerTheCountry } from "../../Helpers/general";
import JobPostingFilterOperationContext from "../../context/JobPostingFilterOperationContext";

const LocationsFilter = () => {
  //
  const {
    allLabels,
    allLocationsWithCountries,
    activeLocationFilter,
    updateTheFilterData,
  } = useContext(JobPostingFilterOperationContext);

  const [selectBoxStatus, setSelectBoxStatus] = useState("inactive");

  // This function used open the drop down options. Here, set the select-box status state to "active".
  const selectBoxOnClickHandler = (event) => {
    event.preventDefault();
    selectBoxStatus !== "active"
      ? setSelectBoxStatus("active")
      : setSelectBoxStatus("inactive");
  };

  //
  const selectBoxEnterHandler = (event) => {
    if (event.key === "Enter") {
      selectBoxStatus !== "active"
        ? setSelectBoxStatus("active")
        : setSelectBoxStatus("inactive");
    }
  };

  // This function used close the drop down options. Here, set the select-box status state to "inactive".
  const closeSelectBox = () => {
    setSelectBoxStatus("inactive");
  };

  //
  useEffect(() => {
    // if drop down is open then  add the function close select-box function to window. This is used to close the drop down user click outside.
    if (selectBoxStatus === "active") {
      window.addEventListener("click", closeSelectBox);
    }
    // Cleanup function previous action.
    return () => {
      window.removeEventListener("click", closeSelectBox);
    };
  }, [selectBoxStatus]);

  //
  const countriesWithLocations = listOutTheLocationsAsPerTheCountry(
    allLocationsWithCountries
  );

  //
  const locationOptionOnClickHandler = (currentSelectedLocation) => {
    updateTheFilterData("selectedLocation", currentSelectedLocation);
  };

  //
  const selectBoxOptionEnterHandler = (event, currentSelectedLocation) => {
    if (event.key === "Enter") {
      updateTheFilterData("selectedLocation", currentSelectedLocation);
      closeSelectBox();
    }
  };

  return (
    <div className="module_options-item">
      <label className="module_options-label" for="jobsLocation">
        {allLabels.by_location_label}
      </label>
      <div className="module-jobs_item--filters">
        <div
          className={`select ${selectBoxStatus === "active" ? "toggle" : ""}`}
          onClick={selectBoxOnClickHandler}
        >
          <div
            className="selectBtn"
            data-type={activeLocationFilter}
            tabIndex="0"
            onKeyDown={selectBoxEnterHandler}
          >
            {activeLocationFilter === ""
              ? allLabels.location_select_box_placeholder
              : activeLocationFilter}
          </div>
          <div className="selectDropdown">
            <div
              className="option"
              data-type=""
              onClick={() => {
                locationOptionOnClickHandler("");
              }}
              onKeyDown={(event) => {
                selectBoxOptionEnterHandler(event, "");
              }}
              tabIndex="0"
            >
              {allLabels.location_select_box_placeholder}
            </div>
            {countriesWithLocations.map((value, index) => (
              <div key={index}>
                <div className="strong-option">{value.country}</div>
                {value.locations.map((item) => (
                  <div
                    className="option"
                    data-type={item}
                    onClick={() => {
                      locationOptionOnClickHandler(item);
                    }}
                    onKeyDown={(event) => {
                      selectBoxOptionEnterHandler(event, item);
                    }}
                    tabIndex="0"
                  >
                    {item}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationsFilter;
