//
export const initialUpdateOfTheJobPosts = (
  jobPostsAsPerTheCondition,
  data,
  noOfItemsPerPage
) => {
  //
  let allJobPosts = data.allJobPosts;

  //
  let totalNumberOfPages = Math.ceil(allJobPosts.length / noOfItemsPerPage);

  //
  let jobPostsAsPerTheCurrentPageNo = allJobPosts.slice(0, noOfItemsPerPage);

  //
  return {
    totalNumberOfPages: totalNumberOfPages,
    jobPosts: jobPostsAsPerTheCurrentPageNo,
  };
};

const findCommonElements = (arr1, arr2) => {
  return arr1.some((item) => arr2.includes(item));
};

//
const checkActiveFilterInCurrentJobPost = (filterItem, jobPost, filterType) => {
  //
  if (filterType === "searchKeyword" && filterItem !== "") {
    //
    let jobPostName = jobPost.name.toLowerCase();
    let jobListID = jobPost.requisition_no.toUpperCase();

    if (
      jobPostName.includes(filterItem.trim().toLowerCase()) ||
      jobListID === filterItem.trim().toUpperCase()
    ) {
      return true;
    } else {
      return false;
    }
  }

  //
  if (filterType === "topic" && filterItem.length !== 0) {
    if (findCommonElements(jobPost.job_posting_topic_slugs, filterItem)) {
      return true;
    } else {
      return false;
    }
  }

  //
  if (filterType === "location" && filterItem !== "") {
    if (jobPost.job_posting_location === filterItem) {
      return true;
    } else {
      return false;
    }
  }
};

//
const getAllJobPostsAsPerTheFilters = (jobPosts, filtersAndData) => {
  //
  let allJobPosts = [...jobPosts];

  let filteredJobPosts = allJobPosts.filter((item) => {
    //
    let flags = [];

    //
    flags.push(
      checkActiveFilterInCurrentJobPost(
        filtersAndData.searchKeyword,
        item,
        "searchKeyword"
      )
    );

    //
    flags.push(
      checkActiveFilterInCurrentJobPost(
        filtersAndData.activeTopicFilter,
        item,
        "topic"
      )
    );

    //
    flags.push(
      checkActiveFilterInCurrentJobPost(
        filtersAndData.activeLocationFilter,
        item,
        "location"
      )
    );

    //
    return flags.includes(false) ? false : true;
  });

  return filteredJobPosts ? filteredJobPosts : [];
};

//
export const updateTheJobPostsAsPerAppliedFilters = (
  jobPostsAsPerTheCondition,
  data,
  noOfItemsPerPage
) => {
  //
  let allJobPosts = data.allJobPosts;
  let currentActivePageNumber = data.filtersAndData.currentActivePageNumber;

  //
  let allJobPostsAsPerTheFilters = getAllJobPostsAsPerTheFilters(
    allJobPosts,
    data.filtersAndData
  );

  // console.log(allJobPostsAsPerTheFilters);

  //
  let totalNumberOfPages = Math.ceil(
    allJobPostsAsPerTheFilters.length / noOfItemsPerPage
  );

  //
  if (currentActivePageNumber === totalNumberOfPages) {
    //
    return {
      totalNumberOfPages: totalNumberOfPages,
      jobPosts: allJobPostsAsPerTheFilters,
    };
  } else {
    //
    let jobPostsAsPerTheCurrentPageNo = allJobPostsAsPerTheFilters.slice(
      0,
      currentActivePageNumber * noOfItemsPerPage
    );

    //
    return {
      totalNumberOfPages: totalNumberOfPages,
      jobPosts: jobPostsAsPerTheCurrentPageNo,
    };
  }
};
