import { useContext } from "react";
import { htmlDecode, getSortedTopicsList } from "../../Helpers/general";
import JobPostingFilterOperationContext from "../../context/JobPostingFilterOperationContext";

const CategoriesFilter = () => {
  //
  const { allLabels, allTopics, activeTopicFilter, updateTheFilterData } =
    useContext(JobPostingFilterOperationContext);

  //
  const topicChangeHandler = (event) => {
    let currentSelectedTopics;

    if (event.target.checked) {
      currentSelectedTopics = [...activeTopicFilter, event.target.value];
    } else {
      currentSelectedTopics = [...activeTopicFilter].filter((e) => {
        return e !== event.target.value;
      });
    }

    updateTheFilterData("selectedTopic", currentSelectedTopics);
  };

  let topicsArray = getSortedTopicsList(allTopics);

  const categoryOptionEnterHandler = (event, value) => {
    //
    if (event.key === "Enter") {
      //
      let currentSelectedTopics;

      if (!activeTopicFilter.includes(value)) {
        currentSelectedTopics = [...activeTopicFilter, value];
      } else {
        currentSelectedTopics = [...activeTopicFilter].filter((e) => {
          return e !== value;
        });
      }
      updateTheFilterData("selectedTopic", currentSelectedTopics);
    }
  };

  return (
    <div className="module_options-item">
      <label className="module_options-label" for="jobsTopic">
        {allLabels.by_topic_label}
      </label>
      <ul className="module_options-tags">
        {topicsArray.map((value, index) => (
          <li>
            <input
              className="module_input"
              id={`cat` + index}
              type="checkbox"
              value={value.slug}
              checked={activeTopicFilter.includes(value.slug) ? "checked" : ""}
              onChange={topicChangeHandler}
              onKeyDown={(event) => {
                categoryOptionEnterHandler(event, value.slug);
              }}
            />{" "}
            <label className="module_label" for={`cat` + index}>
              {htmlDecode(value.name)}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoriesFilter;
