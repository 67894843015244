//Local environments URLS
//export const LOCAL_HOST = "http://fqm-careers.wpress.local";

// Staging environments URLS
// export const LOCAL_HOST = "https://fqm-careers.wpress.dk";

// Production environments URLS
export const LOCAL_HOST = "https://firstquantumcareers.com";

// Api url
export const PRODUCTION_API_URL = `${LOCAL_HOST}/wp-json/fqm-production-api/v1/items`;

export const ENGLISH_LABELS_API_URL = `${LOCAL_HOST}/wp-json/fqm-production-api/v1/english-labels`;

export const SPANISH_LABELS_API_URL = `${LOCAL_HOST}/wp-json/fqm-production-api/v1/spanish-labels`;

export const SINGLE_JOB_API_URL = `${LOCAL_HOST}/wp-json/fqm-production-api/v1/job-post`;

//
export const JOB_POSTS_UPDATING_OPERATIONS = {
  initialUpdateOfTheJobPosts: "initial-update-of-the-job-posts",
  updateTheJobPostsAsperOnlyThePageNo:
    "Update-the-job-posts-as-per-only-page-no",
  updateTheJobPostsAsPerAppliedFilters:
    "Update-the-job-posts-as-per-the-applied-filters",
};

//
export const FILTER_DATA_UPDATING_OPERATIONS = {
  updateTheSearchKeywordInTheFilterData:
    "update-the-search-keyword-in-the-filter-data",
  updateTheSelectedTopicInTheFilterData:
    "update-the-selected-topic-in-the-filter-data",
  updateTheSelectedLocationInTheFilterData:
    "update-the-selected-location-in-the-filter-data",
  updateTheCurrentPageInTheFilterData:
    "update-the-current-page-in-the-filter-data",
};

//
export const JOB_POSTS_PER_PAGE = 6;
