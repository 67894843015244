import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { SINGLE_JOB_API_URL } from "../../constants/general";
import { customEncodeURI, htmlDecode } from "../../Helpers/general";
import TheApiFailurePage from "../FailedMessagePages/TheApiFailurePage";
import JobPostingAPIDataContext from "../../context/JobPostingAPIDataContext";

//
const getTheJobPostDetailsBySlug = (slug, allJobPosts) => {
  //
  let currentJobPost = allJobPosts.filter((value) => {
    return slug === value.slug ? true : false;
  });

  return currentJobPost.length !== 0 ? currentJobPost[0] : false;
};

const JobDetailsPage = () => {
  //
  const { slug } = useParams();

  //
  const [currentSingleJobData, setCurrentSingleJobData] = useState(false);

  //
  const [ApiError, setApiError] = useState(false);

  //
  const { allJobPosts, allLabels, currentPageLanguage } = useContext(
    JobPostingAPIDataContext
  );

  //
  const currentJobPost = getTheJobPostDetailsBySlug(slug, allJobPosts);

  useEffect(() => {
    if (currentJobPost) {
      setCurrentSingleJobData(currentJobPost);
    } else {
      const getAllData = async (slug) => {
        try {
          //
          const { data } = await axios.get(
            SINGLE_JOB_API_URL + "?slug=" + slug
          );

          //
          setCurrentSingleJobData(data);
        } catch (error) {
          //
          setApiError(true);
        }
      };
      getAllData(slug);
    }
  }, [currentJobPost, slug, setCurrentSingleJobData]);

  const parsedURL = window.location.href.split("?");
  const currentUrl = parsedURL[0];
  const queryString = parsedURL[1] ? "?" + customEncodeURI(parsedURL[1]) : "";
  const newTitle =
    htmlDecode(currentSingleJobData.name) + " - " + allLabels.site_title;

  if (document.title !== newTitle && currentSingleJobData.name) {
    document.title = newTitle;
  }
  const currentPageTitle = newTitle;

  // Scroll to the top of the page after render.
  useEffect(() => {
    document.querySelector(".content-block").scrollIntoView();
  });

  if (currentSingleJobData) {
    return (
      <div className="module_container--inner">
        <div className="module-jobs_details">
          <div className="module-jobs_details_container">
            <div className="module_button-container">
              <Link
                to={
                  currentPageLanguage === "eng"
                    ? "/" + queryString
                    : "/es/" + queryString
                }
                className="button button--more button--back"
              >
                {allLabels.back_to_results_label}
              </Link>
            </div>
            <h2 className="module-jobs_details-title">
              {htmlDecode(currentSingleJobData.name)}
            </h2>
            <div className="module-jobs_details-header">
              <div className="module-jobs_details-header-top">
                <div className="module-jobs_details-grid">
                  <div className="module-jobs_details-col">
                    <span className="module-jobs_details-label">
                      {allLabels.requisition_no_label}:{" "}
                    </span>
                    <span className="module-jobs_details-value">
                      {currentSingleJobData.requisition_no
                        ? currentSingleJobData.requisition_no
                        : ""}
                    </span>
                  </div>
                  {currentSingleJobData.requisition_no !== "JR106" ? (
                    <div className="module-jobs_details-col">
                      <span className="module-jobs_details-label">
                        {allLabels.country_label}:{" "}
                      </span>
                      <span className="module-jobs_details-value">
                        {currentSingleJobData.country
                          ? currentSingleJobData.country
                          : ""}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="module-jobs_details-col">
                    <span className="module-jobs_details-label">
                      {allLabels.contract_type_label}:{" "}
                    </span>
                    <span className="module-jobs_details-value">
                      {currentSingleJobData.contract_type
                        ? currentSingleJobData.contract_type
                        : ""}
                    </span>
                  </div>
                  {currentSingleJobData.requisition_no !== "JR106" ? (
                    <div className="module-jobs_details-col">
                      <span className="module-jobs_details-label">
                        {" "}
                        {allLabels.location_label}:{" "}
                      </span>
                      <span className="module-jobs_details-value">
                        {currentSingleJobData.job_posting_location
                          ? currentSingleJobData.job_posting_location
                          : ""}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="module-jobs_details-col">
                    <span className="module-jobs_details-label">
                      {allLabels.categories_label}:{" "}
                    </span>
                    <span className="module-jobs_details-value">
                      {currentSingleJobData.job_posting_topic_text
                        ? htmlDecode(
                            currentSingleJobData.job_posting_topic_text
                          )
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
              <div className="module-jobs_details-header-bottom">
                <div className="a2a_kit a2a_kit_size_32 a2a_default_style">
                  <ul>
                    <li>
                      <LinkedinShareButton
                        url={currentUrl}
                        title={currentPageTitle}
                        className="linked-in-share-button"
                      >
                        <i className="q4-icon_linkedin"></i>
                      </LinkedinShareButton>
                    </li>
                    <li>
                      <TwitterShareButton
                        url={currentUrl}
                        title={currentPageTitle}
                        className="twitter-share-button"
                      >
                        <i className="q4-icon_twitter"></i>
                      </TwitterShareButton>
                    </li>
                    <li>
                      <FacebookShareButton
                        url={currentUrl}
                        quote={currentPageTitle}
                        className="facebook-share-button"
                      >
                        <i className="q4-icon_facebook"></i>
                      </FacebookShareButton>
                    </li>
                  </ul>
                </div>
                {currentSingleJobData.apply_url ? (
                  <div className="module-job_apply">
                    <a
                      className="btn"
                      href={currentSingleJobData.apply_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {allLabels.apply_now_label}
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              className="module-jobs_details-body"
              dangerouslySetInnerHTML={{
                __html: currentSingleJobData.job_posting_description
                  ? currentSingleJobData.job_posting_description
                  : "",
              }}
            ></div>

            <div className="module-jobs_details-footer">
              <div className="module_button-container">
                <Link
                  to={
                    currentPageLanguage === "eng"
                      ? "/" + queryString
                      : "/es/" + queryString
                  }
                  className="button button--more button--back"
                >
                  {allLabels.back_to_results_label}
                </Link>
              </div>
              {currentSingleJobData.apply_url ? (
                <div className="module-job_apply-footer">
                  <a
                    className="btn"
                    href={currentSingleJobData.apply_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {allLabels.apply_now_label}
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    if (ApiError) {
      return <TheApiFailurePage />;
    } else {
      return false;
    }
  }
};

export default JobDetailsPage;
