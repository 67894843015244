import { FILTER_DATA_UPDATING_OPERATIONS } from "../constants/general";
import {
  updateTheSearchKeywordInTheFilterData,
  updateTheSelectedTopicInTheFilterData,
  updateTheSelectedLocationInTheFilterData,
  updateTheCurrentPageInTheFilterData,
} from "../Helpers/filterDataUpdateHelper";

const filterDataReducer = (filterData, action) => {
  //
  switch (action.type) {
    //
    case FILTER_DATA_UPDATING_OPERATIONS.updateTheSearchKeywordInTheFilterData:
      return updateTheSearchKeywordInTheFilterData(
        { ...filterData },
        action.data
      );

    //
    case FILTER_DATA_UPDATING_OPERATIONS.updateTheSelectedTopicInTheFilterData:
      return updateTheSelectedTopicInTheFilterData(
        { ...filterData },
        action.data
      );

    //
    case FILTER_DATA_UPDATING_OPERATIONS.updateTheSelectedLocationInTheFilterData:
      return updateTheSelectedLocationInTheFilterData(
        { ...filterData },
        action.data
      );

    //
    case FILTER_DATA_UPDATING_OPERATIONS.updateTheCurrentPageInTheFilterData:
      return updateTheCurrentPageInTheFilterData(
        { ...filterData },
        action.data
      );

    default:
      return { ...filterData };
  }
};

export default filterDataReducer;
