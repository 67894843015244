import {
  useEffect,
  useState,
  useContext,
  useReducer,
  createContext,
} from "react";

import { createBrowserHistory } from "history";
import useQuery from "../hooks/useQuery";
import {
  getTheURLAsPerTheFilters,
  getTheURLQueryString,
  getDefaultFilterVales,
} from "../Helpers/general";

import {
  JOB_POSTS_UPDATING_OPERATIONS,
  FILTER_DATA_UPDATING_OPERATIONS,
} from "../constants/general";

import JobPostingAPIDataContext from "./JobPostingAPIDataContext";
import jobPostsAsPerTheConditionReducer from "../reducers/jobPostsAsPerTheConditionReducer";
import filterDataReducer from "../reducers/filterDataReducer";

const JobPostingFilterOperationContext = createContext({
  allTopics: [],
  allLocationsWithCountries: [],
  allLabels: [],
  currentPageLanguage: "eng",
  searchKeyword: "",
  activeTopicFilter: [],
  activeLocationFilter: "",
  currentActivePageNumber: 1,
  jobPostsAsPerTheCondition: [],
  currentURLQueryString: "",
  updateTheFilterData: () => {},
  applyTheFilters: () => {},
});

export const JobPostingFilterOperationContextProvider = (props) => {
  //
  const {
    allJobPosts,
    allTopics,
    allLocationsWithCountries,
    allLabels,
    currentPageLanguage,
  } = useContext(JobPostingAPIDataContext);

  //
  const query = useQuery();

  //
  const { defaultSearchKeyword, defaultLocation, defaultTopics } =
    getDefaultFilterVales(query);

  // The applied filter data store in a state.
  const [appliedFilters, setAppliedFilter] = useState({
    searchKeyword: defaultSearchKeyword,
    activeTopicFilter: defaultTopics,
    activeLocationFilter: defaultLocation,
    currentActivePageNumber: 1,
  });

  // The selected filter data store in a state.
  const [filterData, dispatchFilterDataUpdateAction] = useReducer(
    filterDataReducer,
    {
      searchKeyword: defaultSearchKeyword,
      activeTopicFilter: defaultTopics,
      activeLocationFilter: defaultLocation,
      currentActivePageNumber: 1,
    }
  );

  // Job posts as per the applied filter data
  const [jobPostsAsPerTheCondition, dispatchJobPostsAsPerTheConditionAction] =
    useReducer(jobPostsAsPerTheConditionReducer, {
      totalNumberOfPages: 0,
      jobPosts: [],
    });

  //
  const applyTheFilters = () => {
    //
    let history = createBrowserHistory();
    let updatedUrl = getTheURLAsPerTheFilters(filterData);

    //
    history.replace(updatedUrl);

    // combine the user selected filters to applied filters
    setAppliedFilter(filterData);
  };

  //
  const updateTheFilterData = (filterType, filterItemData) => {
    //
    switch (filterType) {
      case "searchKeyword":
        dispatchFilterDataUpdateAction({
          type: FILTER_DATA_UPDATING_OPERATIONS.updateTheSearchKeywordInTheFilterData,
          data: filterItemData,
        });
        break;

      case "selectedTopic":
        dispatchFilterDataUpdateAction({
          type: FILTER_DATA_UPDATING_OPERATIONS.updateTheSelectedTopicInTheFilterData,
          data: filterItemData,
        });
        break;

      case "selectedLocation":
        dispatchFilterDataUpdateAction({
          type: FILTER_DATA_UPDATING_OPERATIONS.updateTheSelectedLocationInTheFilterData,
          data: filterItemData,
        });
        break;

      case "nextPage":
        dispatchFilterDataUpdateAction({
          type: FILTER_DATA_UPDATING_OPERATIONS.updateTheCurrentPageInTheFilterData,
          data: filterItemData,
        });
        //
        // applyTheFilters();
        setAppliedFilter({
          ...filterData,
          currentActivePageNumber: filterItemData,
        });
        break;

      default:
        console.log("Invalid operation");
        break;
    }
  };

  // console.log(filterData);
  // console.log(appliedFilters);

  // Update the job post as per the applied filters
  useEffect(() => {
    //
    let dispatchData = {
      allJobPosts: allJobPosts,
    };

    if (
      appliedFilters.searchKeyword === "" &&
      appliedFilters.activeTopicFilter.length === 0 &&
      appliedFilters.activeLocationFilter === ""
    ) {
      if (appliedFilters.currentActivePageNumber !== 1) {
        //
        dispatchData.filtersAndData = appliedFilters;
        //
        dispatchJobPostsAsPerTheConditionAction({
          type: JOB_POSTS_UPDATING_OPERATIONS.updateTheJobPostsAsperOnlyThePageNo,
          data: dispatchData,
        });
      } else {
        //
        dispatchJobPostsAsPerTheConditionAction({
          type: JOB_POSTS_UPDATING_OPERATIONS.initialUpdateOfTheJobPosts,
          data: dispatchData,
        });
      }
    } else {
      //
      dispatchData.filtersAndData = appliedFilters;
      //
      dispatchJobPostsAsPerTheConditionAction({
        type: JOB_POSTS_UPDATING_OPERATIONS.updateTheJobPostsAsPerAppliedFilters,
        data: dispatchData,
      });
    }
  }, [appliedFilters, allJobPosts]);

  //
  let currentURLQueryString = getTheURLQueryString(appliedFilters);
  currentURLQueryString = currentURLQueryString ? currentURLQueryString : "";

  return (
    <JobPostingFilterOperationContext.Provider
      value={{
        allTopics: allTopics,
        allLocationsWithCountries: allLocationsWithCountries,
        allLabels: allLabels,
        currentPageLanguage: currentPageLanguage,
        searchKeyword: filterData.searchKeyword,
        activeTopicFilter: filterData.activeTopicFilter,
        activeLocationFilter: filterData.activeLocationFilter,
        currentActivePageNumber: filterData.currentActivePageNumber,
        jobPostsAsPerTheCondition: jobPostsAsPerTheCondition,
        currentURLQueryString: currentURLQueryString,
        updateTheFilterData: updateTheFilterData,
        applyTheFilters: applyTheFilters,
      }}
    >
      {props.children}
    </JobPostingFilterOperationContext.Provider>
  );
};

export default JobPostingFilterOperationContext;
