import { LOCAL_HOST } from "../constants/general";

if (!String.prototype.replaceAll) {
  // eslint-disable-next-line
  String.prototype.replaceAll = function (str, newStr) {
    // If a regex pattern
    if (
      Object.prototype.toString.call(str).toLowerCase() === "[object regexp]"
    ) {
      return this.replace(str, newStr);
    }

    // If a string
    return this.replace(new RegExp(str, "g"), newStr);
  };
}

//
export const htmlDecode = (input) => {
  let doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};

//
const sortLocationsForPanama = (locations) => {
  //
  return locations.sort((a, b) => {
    //
    let sortValueOfA;
    let sortValueOfB;

    // eslint-disable-next-line default-case
    switch (a) {
      case "Panama City":
        sortValueOfA = 1;
        break;
      case "Colon":
        sortValueOfA = 2;
        break;
      case "Port":
        sortValueOfA = 3;
        break;
    }

    // eslint-disable-next-line default-case
    switch (b) {
      case "Panama City":
        sortValueOfB = 1;
        break;
      case "Colon":
        sortValueOfB = 2;
        break;
      case "Port":
        sortValueOfB = 3;
        break;
    }

    if (sortValueOfA === 0) return 1;
    else if (sortValueOfB === 0) return -1;
    else return sortValueOfA - sortValueOfB;
  });
};

const getTheIndexOfTheCurrentCountry = (
  countriesWithLocations,
  currentCountry
) => {
  //
  let allCountriesIndex = countriesWithLocations.map(function (value) {
    return value.country;
  });

  //
  return allCountriesIndex.indexOf(currentCountry);
};

export const listOutTheLocationsAsPerTheCountry = (
  allLocationsWithCountries
) => {
  //
  let countriesWithLocations = [];

  if (allLocationsWithCountries) {
    //
    Object.keys(allLocationsWithCountries).forEach((key) => {
      //
      let currentLocationWithCountry = allLocationsWithCountries[key];

      if (countriesWithLocations.length !== 0) {
        //
        let indexOfTheCurrentCountry = getTheIndexOfTheCurrentCountry(
          countriesWithLocations,
          currentLocationWithCountry.country
        );

        // if already added current location's country then add the location under that country
        if (indexOfTheCurrentCountry !== -1) {
          //
          countriesWithLocations[indexOfTheCurrentCountry] = {
            ...countriesWithLocations[indexOfTheCurrentCountry],
            locations: [
              ...countriesWithLocations[indexOfTheCurrentCountry].locations,
              currentLocationWithCountry.job_posting_location,
            ],
          };
        } else {
          //
          countriesWithLocations.push({
            country: currentLocationWithCountry.country,
            locations: [currentLocationWithCountry.job_posting_location],
          });
        }
      } else {
        //
        countriesWithLocations.push({
          country: currentLocationWithCountry.country,
          locations: [currentLocationWithCountry.job_posting_location],
        });
      }
    });

    let countriesWithSortedLocations = [];

    countriesWithLocations.forEach((value) => {
      countriesWithSortedLocations.push({
        country: value.country,
        locations:
          value.country === "Panama"
            ? sortLocationsForPanama(value.locations)
            : value.locations.sort(),
      });
    });

    return countriesWithSortedLocations;
  } else {
    return [];
  }
};

export const getSortedTopicsList = (allTopics) => {
  //
  let topics = { ...allTopics };
  let topicsArray = [];

  Object.keys(topics).forEach((key) => topicsArray.push(topics[key]));

  topicsArray = topicsArray.sort((a, b) => a.name.localeCompare(b.name));

  return topicsArray;
};

//
export const getCurrentLanguageFromURL = () => {
  //
  let currentURL = window.location.href;

  if (currentURL === LOCAL_HOST || currentURL === LOCAL_HOST + "/") {
    return "eng";
  } else if (
    currentURL === LOCAL_HOST + "/es" ||
    currentURL === LOCAL_HOST + "/es/"
  ) {
    return "spanish";
  } else {
    //
    let pathsInUrlSplitted = window.location.pathname
      .split("/")
      .filter(function (val) {
        return val !== null && val !== "";
      });

    if (pathsInUrlSplitted.length === 0) {
      return "eng";
    } else {
      if (pathsInUrlSplitted[0] === "es") {
        return "spanish";
      } else {
        return "eng";
      }
    }
  }
};

//
export const getTheURLQueryString = (filterData) => {
  //
  let queryStrings = "?";

  const searchKeyword = filterData.searchKeyword;

  const activeTopicFilter = filterData.activeTopicFilter;

  const activeLocationFilter = filterData.activeLocationFilter;

  if (searchKeyword !== "") {
    queryStrings += "SearchTerm=" + searchKeyword;
  }

  if (activeTopicFilter.length !== 0) {
    //
    if (queryStrings !== "?") {
      queryStrings += "&";
    }

    //
    if (activeTopicFilter.length > 0) {
      //
      queryStrings += "topics=" + activeTopicFilter.join(",");
    } else {
      //
      queryStrings += "topics=" + activeTopicFilter[0];
    }
  }

  if (activeLocationFilter !== "") {
    //
    if (queryStrings !== "?") {
      queryStrings += "&";
    }

    queryStrings += "location=" + activeLocationFilter;
  }

  if (queryStrings !== "?") {
    return customEncodeURI(queryStrings);
  } else {
    return false;
  }
};

//
export const customEncodeURI = (url) => {
  //
  let uri = url.trim();
  uri = uri.replaceAll(" ", "%20");
  uri = uri.replaceAll(",", "%2C");
  return uri;
};

//
export const getTheURLAsPerTheFilters = (filterData) => {
  //
  const queryStrings = getTheURLQueryString(filterData);

  const currentURLWithoutQueryString = window.location.href.split("?")[0];

  let newURL = currentURLWithoutQueryString;

  if (queryStrings) {
    newURL += customEncodeURI(queryStrings);
    // newURL += queryStrings;
  }
  return newURL;
};

//
export const getDefaultFilterVales = (query) => {
  //
  const defaultSearchKeyword = query.get("SearchTerm")
    ? query.get("SearchTerm")
    : "";
  //
  const defaultLocation = query.get("location") ? query.get("location") : "";
  //
  const defaultTopics = query.get("topics")
    ? query.get("topics").split(",")
    : [];

  return {
    defaultSearchKeyword: defaultSearchKeyword,
    defaultLocation: defaultLocation,
    defaultTopics: defaultTopics,
  };
};
