import { useContext } from "react";
import JobPostingFilterOperationContext from "../context/JobPostingFilterOperationContext";

const SearchBar = () => {
  //
  const { allLabels, searchKeyword, updateTheFilterData, applyTheFilters } =
    useContext(JobPostingFilterOperationContext);

  // console.log(searchKeyword);
  //
  const searchKeywordChangeHandler = (event) => {
    const currentEnteredKeyword = event.target.value;
    updateTheFilterData("searchKeyword", currentEnteredKeyword);
  };

  //
  const searchButtonOnClickHandler = (event) => {
    event.preventDefault();
    applyTheFilters();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      applyTheFilters();
    }
  };

  return (
    <div className="module-jobs_search-input">
      <input
        type="text"
        name="jobsSearch"
        className="module_input"
        id="job-search"
        placeholder={allLabels.search_for_job_placeholder}
        aria-label={allLabels.search_for_job_placeholder}
        defaultValue={searchKeyword}
        onChange={searchKeywordChangeHandler}
        onKeyDown={handleKeyDown}
      />{" "}
      <input
        type="submit"
        value="Search"
        className="module-jobs_search-submit"
        onClick={searchButtonOnClickHandler}
      />
    </div>
  );
};

export default SearchBar;
