/* eslint-disable no-undef */
const TheApiFailurePage = () => {
  return (
    <div className="module-jobs_item--content">
      <div className="module-jobs_item">
        <h2>{api_error_title}</h2>
        <p className="main-message">{api_error_user_polite_message}</p>
        <p className="main-message">{api_error_main_message}</p>
      </div>
    </div>
  );
};

export default TheApiFailurePage;
